
import './App.css';
import React,{Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UtilityProvider } from './Hooks/UtilityProvider';
import { CartProvider } from 'react-use-cart';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {LogIn} from './Views/LogIn'
import { Toaster } from 'react-hot-toast';


const ContactForm = React.lazy(()=>import('./Views/ContactForm'))
const OrderConfirm = React.lazy(()=>import("./Views/OrderConfirm"))
const TrackMyOrder = React.lazy(()=>import('./Views/TrackMyOrder'))
const AllProducts = React.lazy(() => import('./Views/AllProducts'));
const Checkout = React.lazy(() => import('./Views/Checkout'));
const Home = React.lazy(() => import('./Views/Home'));
const ProductView = React.lazy(() => import('./Views/ProductView'));
const CartPage = React.lazy(() => import('./Views/CartPage'));
const SignUp = React.lazy(() => import('./Views/SignUp'));
const Error404 = React.lazy(() => import('./Views/Error404'));
const Profile = React.lazy(() => import('./Views/UserProfile/Profile'));
const WishList = React.lazy(() => import('./Views/WishList'));
const PrivacyPolicy = React.lazy(() => import('./Views/PrivacyPolicy'));
const ShippingPolicy = React.lazy(() => import('./Views/ShippingPolicy'));
const TermsofUse = React.lazy(() => import('./Views/TermsofUse'));




const queryClient = new QueryClient()
function App() {


  return (


    // <PurchaseHistory />
    <Router>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
          <UtilityProvider>
            <CartProvider>
            <Suspense>
            <Toaster/>
              <Routes >
                <Route exact path='/' Component={Home} />
                <Route path="/all-products/:category?" Component={AllProducts} />
                <Route path='/checkout' Component={Checkout} />
                <Route path='/Profile' Component={Profile}/>
                <Route exact path="/product/:id" Component={ProductView}/>
                <Route path='/wishlist' Component={WishList}/>
                <Route path='/cart' Component={CartPage} />
                <Route path='/login' Component={LogIn} />
                <Route path='/signup' Component={SignUp} />
                <Route path='*' Component={Error404} />
                <Route path='/PrivacyPolicy' Component={PrivacyPolicy} />
                <Route path='/ShippingPolicy' Component={ShippingPolicy} />
                <Route path='/TermsofUse' Component={TermsofUse} />

                <Route path='/orderConfirmed' Component={OrderConfirm}/>
                <Route path='/trackmyorder' Component={TrackMyOrder}/>
                <Route path='/contact' Component={ContactForm}/>
                
              </Routes>
              </Suspense>

            </CartProvider>
          </UtilityProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>



    </Router>




  )
}

export default App;
