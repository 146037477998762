import React from "react";
import {
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Drawer,
  Card,
} from "@material-tailwind/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowLeft, faBars, faList, faLocationDot, faPowerOff, faUserGroup, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import MobileSidePanelItem from "./MobileSidePanelItem";

export function MobileSideBar() {
  const [open, setOpen] = React.useState(1);
  const [nestedopen, setNestedOpen] = React.useState(0);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const navigate = useNavigate()

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const handleNestedOpen = (value) => {
    setNestedOpen(nestedopen === value ? 0 : value);
  };
  const openDrawer = () => {
    setIsDrawerOpen(true);
    document.body.style.overflow = 'hidden'; // Disable scrolling
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    document.body.style.overflow = 'auto'; // Enable scrolling
  };

  const handleSignout = () => {
    // setIsDrawerOpen(false);
    closeDrawer()
    localStorage.removeItem('user');
    navigate('/signup');

  }

  return (
    <div className="w-2/3 h-screen z-50">

      <IconButton
        variant="text"
        className="h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent focus:outline-none " // Adjusted positioning
        ripple={true}
        onClick={openDrawer}
      >
        {isDrawerOpen ? (
          <FontAwesomeIcon icon={faXmark} size="2x" style={{ color: "black" }} />
        ) : (
          <FontAwesomeIcon icon={faBars} size="2x" style={{ color: "black" }} />
        )}
      </IconButton>
      <Drawer open={isDrawerOpen} onClose={closeDrawer} className="w-4/5 ">
        <Card
          color="transparent"
          shadow={false}
          className="h-screen w-full p-4  bg-white overflow-y-scroll bg-blend-overlay"
          
        >

          <div className="mb-2 flex items-center gap-4 p-4">
            <FontAwesomeIcon icon={faArrowLeft} size="1x" onClick={closeDrawer} />
            <img
              src="/icons/Zenko-logo-small.png"
              alt="brand"
              className="h-auto w-auto"
              onClick={()=>{
                closeDrawer()
                navigate(`/`)
              }}
            />

          </div>

          <List>
            <Accordion
              open={open === 1}

            >
              <ListItem className="p-0" selected={open === 1}>
                <AccordionHeader
                  onClick={() => handleOpen(1)}
                  className="border-b-0 p-3 focus:outline-none"
                >
                  <ListItemPrefix>
                    <FontAwesomeIcon icon={faList} className="h-5 w-5" style={{ color: open === 1 ? "#E12E02" : "#888888" }} />
                  </ListItemPrefix>
                  <Typography color="blue-gray" className="mr-auto font-normal ">
                    Categories
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <List className="p-0 ml-2">
                  <Accordion
                    open={nestedopen === 1}

                  >
                    <ListItem className="p-0" selected={nestedopen === 1}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(1)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} rotation={nestedopen === 1 ? 90 : 0} size="1x" className="h-5 w-5 " />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Furniture
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Bedroom" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Dining" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Living Room" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Office" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Sofas" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Bathroom" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Garden Furniture" closeDrawer={closeDrawer} />



                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 2}

                  >
                    <ListItem className="p-0" selected={nestedopen === 2}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(2)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 2 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Home & Decor
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Bedding" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Kitchenware" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Rugs" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Storage" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Decor" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Curtains" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Lighting" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Bathroom Accessories" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Home Office Accessories" closeDrawer={closeDrawer} />
                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 3}

                  >
                    <ListItem className="p-0" selected={nestedopen === 3}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(3)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 3 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Pet Care
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Aquarium" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Pest Control" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Cat Supplies" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Dog Supplies" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Cleaning & Maintenance" closeDrawer={closeDrawer} />

                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 4}

                  >
                    <ListItem className="p-0" selected={nestedopen === 4}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(4)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 4 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Appliances
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Vacuum Cleaners" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Heaters" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Fridges" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Aroma Diffusers & Humidifiers" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Air Conditioners" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="TV" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Fans" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Washers & Dryers" closeDrawer={closeDrawer} />

                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 5}

                  >
                    <ListItem className="p-0" selected={nestedopen === 5}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(5)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 5 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Electronics
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Back Up & Storage" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Battery Chargers & Power" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Computer Accessories" closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Computers & Tablets"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Mobile Accessories"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="USB Gadgets"   closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Printer"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Printer Accessories & Supplies"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Headphones and Earphones"  closeDrawer={closeDrawer} />

                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 6}

                  >
                    <ListItem className="p-0" selected={nestedopen === 6}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(6)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 6 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Cleaning & Maintenance
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Laundry & Cleaning"  closeDrawer={closeDrawer} />


                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 7}

                  >
                    <ListItem className="p-0" selected={nestedopen === 7}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(7)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 7 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Outdoor & Garden
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Boating"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Camping"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Metal Detectors"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Picnic"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Outdoor Shoes"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Home & Garden Others"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Fishing"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Power supply"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Garden Furniture"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Green Houses"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Garden Tools" />
                        <MobileSidePanelItem title="BBQ"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Shading"   closeDrawer={closeDrawer}/>
                        <MobileSidePanelItem title="Pool & Accessories"  closeDrawer={closeDrawer} />

  closeDrawer={closeDrawer}
                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 8}

                  >
                    <ListItem className="p-0" selected={nestedopen === 8}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(8)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 8 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Sports & Fitness
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Basketball & Accessories"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Bikes & Accessories"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Fitness Accessories"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Golf"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Trampolines"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Scooters and Accessories"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Exercise, Gym and Fitness"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Game Tables"  closeDrawer={closeDrawer} />


                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 9}

                  >
                    <ListItem className="p-0" selected={nestedopen === 9}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(9)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 9 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Toys & Games
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Books"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Games"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="DVDs. CDs and Blurays"  closeDrawer={closeDrawer} />

                      </List>  closeDrawer={closeDrawer}
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 10}

                  >
                    <ListItem className="p-0" selected={nestedopen === 10}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(10)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 10 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Health & Beauty
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Cosmetic Storage"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Makeup mirrors"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Massage"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Makeup"   closeDrawer={closeDrawer}/>
                        <MobileSidePanelItem title="Shaving & Hair Removal"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Massage & Relaxation"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Fragrances & Perfumes"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Hair Care"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Nutrition & Supplements"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Personal Care"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Health & Wellbeing"  closeDrawer={closeDrawer} />

                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 11}

                  >
                    <ListItem className="p-0" selected={nestedopen === 11}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(11)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 11 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Fashion
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Casual Shoes"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Coats & Jackets"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Dress Shoes & Boots"   closeDrawer={closeDrawer}/>
                        <MobileSidePanelItem title="Hats & Caps"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Pants & Chinos"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Shirts"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Shorts"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Socks"  closeDrawer={closeDrawer} />  closeDrawer={closeDrawer}
                        <MobileSidePanelItem title="Suits & Tuxedos"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Sweats & Trackpants"   closeDrawer={closeDrawer}/>
                        <MobileSidePanelItem title="Underwear & Socks"  closeDrawer={closeDrawer} />

                        <MobileSidePanelItem title="Activewear"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Bottoms"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Casual Shoes"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Dresses & Playsuits"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Handbags"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Heels"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Intimates"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Jackets & Coats"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Boots"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Socks & Hosiery"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Swimwear"  closeDrawer={closeDrawer} />  closeDrawer={closeDrawer}
                        <MobileSidePanelItem title="Sleepwear"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Knitwear & Sweaters"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Jewellery"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Sneakers & Sports Shoes"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Thongs & Sandals"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Tops & T-shirts"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Uggs & Slippers"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Watches"   closeDrawer={closeDrawer}/>
                        <MobileSidePanelItem title="Jeans"  closeDrawer={closeDrawer} />

                      </List>
                    </AccordionBody>
                  </Accordion>
                  <Accordion
                    open={nestedopen === 12}

                  >
                    <ListItem className="p-0" selected={nestedopen === 12}>
                      <AccordionHeader
                        onClick={() => handleNestedOpen(12)}
                        className="border-b-0 p-3 focus:outline-none"
                      >
                        <ListItemPrefix>
                          <FontAwesomeIcon icon={faAngleRight} size="1x" rotation={nestedopen === 12 ? 90 : 0} className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography color="blue-gray" variant="h6" className="mr-auto font-normal">
                          Baby & Kids
                        </Typography>
                      </AccordionHeader>
                    </ListItem>
                    <AccordionBody className="py-1">
                      <List className="p-0 ml-4">
                        <MobileSidePanelItem title="Ride on Cars, Go-karts & Bikes"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Gates & Playpens"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Kid's Furniture"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Nursing"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Baby & Kids Others"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Stroller"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Girls Clothing"  closeDrawer={closeDrawer} />
                        <MobileSidePanelItem title="Boys Clothing"  closeDrawer={closeDrawer} />

                      </List>
                    </AccordionBody>
                  </Accordion>
                </List>
              </AccordionBody>
            </Accordion>

            <hr className="my-2 border-blue-gray-50" />
            
              <ListItem onClick={()=>{
                navigate(`/trackmyorder`)
                closeDrawer()
              }}>
                <ListItemPrefix>
                  <FontAwesomeIcon icon={faLocationDot} className="h-5 w-5" />
                </ListItemPrefix>
                Track My Order
              </ListItem>
          
          
              <ListItem  onClick={()=>{
                navigate(`/contact`)
                closeDrawer()
              }}>
                <ListItemPrefix>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 -ml-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                  </svg>

                </ListItemPrefix>
                Help and Support
              </ListItem>
         
           {/* <Link to={"/profile"}>
              <ListItem>
                <ListItemPrefix>
                  <FontAwesomeIcon icon={faUserGroup} className="h-5 w-5" />
                </ListItemPrefix>
                Profile
              </ListItem>
            </Link>*/}
            <ListItem onClick={handleSignout} className=" pb-36">
              <ListItemPrefix >
                <FontAwesomeIcon icon={faPowerOff} className="h-5 w-5" />
              </ListItemPrefix>
              Log Out
            </ListItem>
          </List>

        </Card>
      </Drawer>
  </div>

);
}
