import React, { useState } from "react";
import { Input, Button } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { MobileSideBar } from "./MobileSideBar";
import { productSearch } from "../requests/products";
import { useNavigate } from "react-router-dom";


const Searchbar = () => {
    const [activeSearch, setActiveSearch] = useState([]);
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate()

    const handleSearch = async (e) => {
        const text = e.target.value;
        setSearchText(text);

        if (text === '') {
            setActiveSearch([]);
            return;
        }

        try {
            const res = await productSearch(text);
            console.log(res); //res has all the data to be mapped to active search as wished - TODO: Map the active search
            const keywords = res.keywords; // Extract the keywords array from the response

            // Filter the keywords based on the search text and limit the results to 8
            const filteredKeywords = keywords.filter(w => w.includes(text)).slice(0, 8);

            setActiveSearch(filteredKeywords);
        } catch (error) {
            console.error("Search error: ", error);
        }

    };

    const handleredirect = (text) => {
        if (text=='') {
            return
        }
        setActiveSearch([])
        navigate(`/all-products?search=${text}&page=1&limit=36`)

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleredirect(searchText);
    };

    return (
        <form className="xl:w-[440px] lg:w-[320px] w-[300px] relative" onSubmit={handleSubmit}>
            <div className="flex flex-row relative w-full h-10 gap-2 md:w-100vh mt-0 mb-0">
                <div className="flex-grow">
                    <input
                        type="text"
                        id="simple-search-mobile"
                        className="bg-gray-50 border border-gray-400 text-gray-900 text-sm rounded-xl block w-full ps-4 p-2.5 focus:outline-gray-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Looking For Something?"
                        onChange={handleSearch}
                        value={searchText}
                        required
                    />
                </div>
                <Button
                    size="sm"
                    className="rounded-xl bg-Red1 focus:outline-none"
                    onClick={()=>handleredirect(searchText)}
                >
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        size="1x"
                        style={{ color: "white" }}
                    />
                </Button>
                {activeSearch.length > 0 && (
                    <div className="absolute top-14 p-4 bg-gray-100 text-black w-full rounded-xl left-1/2 -translate-x-1/2 flex flex-col gap-2">
                        {activeSearch.map((s, index) => (
                            <span
                                key={index}
                                className="block border-b border-gray-300 last:border-b-0 p-1 cursor-pointer"
                                onClick={()=>handleredirect(s)}
                            >
                                {s}
                            </span>
                        ))}
                    </div>
                )}
            </div>
        </form>
    );
}

export default Searchbar;