import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { ListItem, ListItemPrefix, Typography } from "@material-tailwind/react"
import { Link, useNavigate } from "react-router-dom"

export default function MobileSidePanelItem({ title,closeDrawer }) {
    const navigate = useNavigate()


    const onClick =()=>{
        closeDrawer()
        navigate(`/all-products/${title}?page=1&limit=36`)
        
    }

   
    return (
        
            <ListItem className="py-2" onClick={onClick}>
                <ListItemPrefix>
                    <FontAwesomeIcon icon={faAngleRight} className="h-3 w-5" />
                </ListItemPrefix>

                <Typography variant="small">{title}</Typography>

            </ListItem>

)
}
