import axios from "axios";

const env = process.env.REACT_APP_API_URL
const localUrl="https://ddfc-112-134-147-222.ngrok-free.app"

export const fetchProducts = async (requestBody, page = 1, limit = 36) => {
    try {
        const url = `${env}/filter/product?page=${page}&limit=${limit}`;

        // console.log(url);
        const response = await axios.post(url, requestBody, {
            headers: {
                "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
            }
        });
        // console.log("Products fetched here:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching products:", error);
        return [];
    }
};

export const fetchProductById = async (productId) => {
    try {
        const response = await axios.get(`${env}/product/${productId}`, {
            headers: {
                "ngrok-skip-browser-warning": "69420"//use only when using ngrok
            }
        });
        // console.log("data is recieved")
        // console.log(response.data)
        return response.data;

    } catch (error) {
        console.error(error);
        return null;
    }
}

export const productSearch = async (search) => {
    try {
        const response = await axios.get(`${env}/search?q=${search}`, {
            headers: {
                "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
            }
        });
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}


export const getSliderData = async () => {
    try {
        const ratingResponse = await axios.get(`${env}/rating/product`, {
            headers: {
                "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
            }
        });
        const rating = ratingResponse.data;
        // console.log(ratingResponse);

        const bestsellingResponse = await axios.get(`${env}/bestselling/product`, {
            headers: {
                "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
            }
        });
        const bestselling = bestsellingResponse.data;
        // console.log(bestsellingResponse);

        return { rating, bestselling };
    } catch (error) {
        console.error("Error fetching slider data:", error);
        return { rating: [], bestselling: [] };
    }
};


// use when setting up lazy loading - intersection API and useref chatgpt ref-https://chatgpt.com/share/d19fb11a-1b15-485e-804f-2a348fe2e934
export const getSliderCatData = async (subCategory) => {
    try {
        const response = await axios.post(`${env}/sub_category/product`, {
            sub_category: subCategory
        });

        return response.data

    } catch (error) {
        console.error("Error fetching slider data:", error);
        return { "error": error };
    }
};

export const getCatSliders = async () => {
    try {
        const livingRoom = await axios.get(`${env}/sub_category/product?q=living room`,{
            headers: {
                "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
            }
        });

        const heater = await axios.get(`${env}/sub_category/product?q=heaters`,{
            headers: {
                "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
            }
        });

        const kitchen = await axios.get(`${env}/sub_category/product?q=kitchenware`,{
            headers: {
                "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
            }
        });

        const vaccum = await axios.get(`${env}/sub_category/product?q=vacuum Cleaners`,{
            headers: {
                "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
            }
        });

        const catcare = await axios.get(`${env}/sub_category/product?q=Cat Supplies`,{
            headers: {
                "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
            }
        });
        
        const livingRoomData = livingRoom.data
        const heaterData = heater.data
        const kitchenData = kitchen.data
        const vaccumData = vaccum.data
        const catcareData = catcare.data
         return{livingRoomData,heaterData,kitchenData,vaccumData,catcareData}



    } catch (error) {
        console.error("Error fetching slider data:", error);
      
        return {heaterData:[],kitchenData:[],vaccumData:[],livingRoomData:[], catcareData:[] };
          }
}


export const getHotDeals = async () => {
    try {
        const response = await axios.get(`${env}/other_filter/product`,{
            params:{
                q:"featured"
            },
            
            headers: {
                    "ngrok-skip-browser-warning": "69420"  // Use only when using ngrok
                }
            
        })
        // console.log(response);
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.status;
    }
}


export const deleteOrder = async(token,reqBody) =>{
    try {
        // console.log("2",reqBody);
        const response = await axios.delete(`${env}/order`, {
            headers: {
                token: token,
            },
            data: reqBody, // Include reqBody as data in the configuration
        });

        return response.status;
    } catch (error) {
        console.log(error);
        return error.response.status;
    }
}