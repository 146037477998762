import axios from "axios";

const env=process.env.REACT_APP_API_URL
const localUrl="https://ddfc-112-134-147-222.ngrok-free.app"

export const registerUser = async (values) => {
    try {
        const response = await axios.post(`${env}/user`, {
            email: values.email,
            password: values.password
        }, {
            headers: {
                "ngrok-skip-browser-warning": "69420" // remove in production - only for ngrok
            }
        });
        
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.status;
    }
};


export const loginUserWithGoogle = async (token) => {
    try {
        const response = await axios.post(`${env}/login/googleJWT`, {
            token: token
        }, {
            headers: {
                "ngrok-skip-browser-warning": "69420" // remove in production - only for ngrok
            }
        });
        // console.log(response);
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.status;
    }
};

export const loginUser = async (values) => {
    try {
        const response = await axios.post(`${env}/login/user`, {
            email: values.email,
            password: values.password
        }, {
            headers: {
                "ngrok-skip-browser-warning": "69420" // remove in production - only for ngrok
            }
        });
        
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.status;
    }
};

export const loginUserBearer=async(token)=>{
    try {
        const response = await axios.post(`${env}/login/googleBEARER`, {
            token: token
        }, {
            headers: {
                "ngrok-skip-browser-warning": "69420" // remove in production - only for ngrok
            }
        });
        // console.log(response);
        return response.data;
    } catch (error) {
        console.log(error);
        return error.response.status;
    }
}


export const getUserByEmail = async (token,email)=>{
    try {
        const response = await axios.post(`${env}/user/email`,{
            email
        },{
            headers:{
                "token":token,
                'ngrok-skip-browser-warning': '69420'

            }
        })
        // console.log(response);
        return response.data

    } catch (error) {
        console.log(error);
        return error.response.status;
    }
}


export const updateAddress = async (user,reqBody)=>{
    try {
        const response = await axios.put(`${env}/address/update`,reqBody,{
            headers:{
                 "token":user.token,
                'ngrok-skip-browser-warning': '69420'
            }
        })
        // console.log(response);
        return response.status
    } catch (error) {
        console.log(error);
        return error.response.status;
    }

}

export const supportMail = async (formData) => {
    try {
      const response = await fetch(`${env}/support/mail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      return response.json();
    } catch (error) {
      console.log("Support Mail Error: ", error);
      throw error;
    }
  };